import React from 'react';
import { IArtefact } from '../../interface/artefacts/artefact';
import ArtefactGoogleMapView from '../googleMap/artefactMaps/ArtefactGoogleMapView';
import CheckInput from '../inputs/CheckInput';
import Loader from '../Loader';

const ViewArtefact: React.FC<{ artefact?: IArtefact; loading: boolean }> = ({ artefact, loading }) => {
  if (loading) {
    return (
      <div className="flex">
        <Loader />
      </div>
    );
  }

  return (
    <div className="w-full my-4">
      <div className="bg-gradient-to-r from-darkPurple to-softPurple p-1 my-5">
        {artefact && <ArtefactGoogleMapView geoPoint={{ lat: artefact?.latitude!, lng: artefact?.longitude! }} />}
      </div>

      <div className="grid gap-5">
        {/* <TableRow
          title="Artefakto pavadinimas angliškai"
          value={artefact?.nameEN}
        /> */}

        <TableRow title="Artefakto pavadinimas" value={artefact?.nameLT} />
        {/* <TableRow
          title="PArtefakto pavadinimas rusiškai"
          value={artefact?.nameRU}
        />
        <TableRow
          title="Audio pavadinimas angliškai"
          value={artefact?.audioRecordings.nameEN}
        /> */}

        {/* <TableRow
          title="Audio pavadinimas rusiškai"
          value={artefact?.audioRecordings.nameRU}
        />
        <TableRow
          title="Artefakto aprašymas angliškai"
          value={artefact?.audioRecordings.nameEN}
        /> */}

        <TableRow title="Artefakto aprašymas" value={artefact?.descriptionLT} />
        {/* <TableRow
          title="Artefakto aprašymas rusiškai"
          value={artefact?.audioRecordings.nameRU}
        /> */}

        <CheckInput value={artefact?.audioRecordings.playAudio!} disable={true} />

        <TableRow title="Audio pavadinimas" value={artefact?.audioRecordings.nameLT} />

        <div className="bg-gray">
          <audio className="w-full" src={artefact?.audioRecordings.sourceLT} controls />
        </div>

        <div>
          <label>Nuotrauka</label>
          <div className="bg-gray p-5">
            <img className="object-cover h-48" src={artefact?.photo} alt="artefact" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewArtefact;

const TableRow: React.FC<{ title: string; value?: string }> = ({ title, value }) => {
  return (
    <div>
      <div>
        <h6>{title}</h6>
      </div>
      <div className="bg-gray">
        <p className="p-4">{value}</p>
      </div>
    </div>
  );
};
