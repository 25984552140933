import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IArtefactArr } from "../../interface/artefacts/artefact";
import { GameRoute } from "../../interface/gameRoutes/Route";
import GoogleMapsView from "../googleMap/routesMaps/GoogleMapsView";
import Loading from "../Loader";

const ViewGameRoute: React.FC<{ gameRoutes?: GameRoute }> = ({
  gameRoutes,
}) => {
  const [polylines, setPolylines] = useState<{ lat: number; lng: number }[]>(
    []
  );
  const [selectedArtefacts, setSelectedArtefacts] = useState<IArtefactArr[]>(
    []
  );
  const [artefacts, setArtefacts] = useState<{ lat: number; lng: number }[]>(
    []
  );
  const id = useParams().id;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const setAllPolylines = () => {
      const newPolylinesArr: { lat: number; lng: number }[] = [];
      gameRoutes?.encodedPolyline.forEach((polyline) => {
        newPolylinesArr.push({ lat: polyline[0], lng: polyline[1] });
      });

      setPolylines(newPolylinesArr);
    };

    setAllPolylines();

    const getAllArtefacts = async () => {
      const newArrtefactArr: { lat: number; lng: number }[] = [];
      const newSelectedArteArr: IArtefactArr[] = [];
      const res = await axios.get("/artefact", {
        withCredentials: true,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });
      res.data.forEach((artefact: IArtefactArr) => {
        if (gameRoutes?.artefactsIds.includes(artefact.id)) {
          newSelectedArteArr.push(artefact);
        }
      });

      newSelectedArteArr.sort(
        (a, b) =>
          gameRoutes!.artefactsIds.indexOf(a.id) -
          gameRoutes!.artefactsIds.indexOf(b.id)
      );

      newSelectedArteArr.forEach((artefact: IArtefactArr) => {
        newArrtefactArr.push({
          lat: artefact.latitude,
          lng: artefact.longitude,
        });
      });

      setSelectedArtefacts(newSelectedArteArr);
      setArtefacts(newArrtefactArr);
      setLoading(false);
    };

    getAllArtefacts();
  }, [gameRoutes]);

  if (loading) {
    return (
      <div className="flex">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full my-4">
      <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 mt-5 bg-gray">
        {selectedArtefacts &&
          selectedArtefacts.map((artefact) => (
            <div
              key={artefact.id}
              className="flex justify-between items-center p-2 bg-darkPurple text-white cursor-pointer hover:bg-softPurple transition"
              onClick={() => navigate(`/artefacts/view/${artefact.id}`)}
            >
              <div className="px-2 truncate">{artefact.nameLT}</div>
              <FontAwesomeIcon icon={faEye} />
            </div>
          ))}
      </div>
      <div className="bg-gradient-to-r from-darkPurple to-softPurple p-1 my-5">
        <GoogleMapsView polylines={polylines} artefacts={artefacts} />
      </div>
      <div className="grid gap-5">
        <TableRow title="Autorius" value={gameRoutes?.author} />

        {/* <TableRow title="Pamadinimas angliškai" value={gameRoutes?.nameEN} /> */}
        <TableRow title="Pavadinimas" value={gameRoutes?.nameLT} />
        {/* <TableRow title="Pamadinimas rusiškai" value={gameRoutes?.nameRU} />
        <TableRow
          title="Aprašymas angliškai"
          value={gameRoutes?.descriptionEN}
        /> */}
        <TableRow title="Aprašymas" value={gameRoutes?.descriptionLT} />
        {/* <TableRow
          title="Aprašymas rusiškai"
          value={gameRoutes?.descriptionRU}
        /> */}
        <TableRow
          title="Ilgis"
          value={`${JSON.stringify(gameRoutes?.length)} m.`}
        />
        <TableRow
          title="Žingsnių skaičius"
          value={JSON.stringify(gameRoutes?.stepCount)}
        />
        <TableRow
          title="Vaikščiojimo laikas minutemis"
          value={JSON.stringify(gameRoutes?.walkingTime)}
        />
        <TableRow
          title="Sudėtingumo lygis"
          value={gameRoutes?.difficultyLevel}
        />

        <div>
          <label>Nuotrauka</label>
          <div className="bg-gray p-5">
            <img className="object-cover h-48" src={gameRoutes?.photo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewGameRoute;

const TableRow: React.FC<{ title: string; value?: string }> = ({
  title,
  value,
}) => {
  return (
    <div>
      <div className="grid items-center">
        <h6>{title}</h6>
      </div>
      <div className="bg-gray">
        <p className="p-4">{value}</p>
      </div>
    </div>
  );
};
